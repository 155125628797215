























































































import { tareaAllDto } from "@/shared/dtos/tareas/tareaAllDto";
import { MessageService } from "@/shared/services/message-service";
import tareaModule from "@/store/modules/tarea-module";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    TareaFormulario: () =>
      import("@/views/proyectos/tareas/tareasFormulario.vue"),
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    TareasListaMovil: () =>
      import("@/views/proyectos/tareas/tareas_lista_movil.vue"),
  },
})
export default class tareas_inventario extends Vue {
  public show_dialog_tarea: boolean = false;
  public id_tarea: number = -1;

  created() {
    this.GetTareas();
    usuarioModule.getmiusuario();
  }

  public get tareas() {
    return tareaModule.tareas_proyecto.filter(
      (x) => x.id_inventario === Number.parseInt(this.$route.params.id)
    );
  }

  public get mi_user() {
    return usuarioModule.mi_usuario;
  }

  public GetTareas() {
    return tareaModule.gettareaes_completoActivos();
  }

  editar(obj: tareaAllDto) {
    this.id_tarea = obj.id;
    this.show_dialog_tarea = true;
  }

  public close_dialog() {
    this.show_dialog_tarea = false;
    this.GetTareas();
    this.id_tarea = -1;
  }

  eliminar(obj: tareaAllDto) {
    MessageService.confirm(
      "¿Seguro que desea eliminar la tarea?",
      "Eliminar",
      (cb: boolean) => {
        if (cb) {
          tareaModule.eliminartarea(obj).then(() => {
            this.close_dialog();
          });
        }
      }
    );
  }
}
