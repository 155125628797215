var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"grey--text subtitle-1"},[_vm._v("Tareas asignadas al inventario")])],1),(!_vm.mi_user.app_movil)?[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":[
        {
          text: 'Nombre',
          value: 'nombre',
        },
        {
          text: 'Fecha inicio',
          value: 'fecha_inicio',
        },
        {
          text: 'Fecha fin',
          value: 'fecha_fin',
        },
        {
          text: 'Estado',
          value: 'estado.nombre',
        },
        {
          text: 'Tipo',
          value: 'tipo.nombre',
        },
        {
          text: 'Proveedor',
          value: 'empresa.nombre',
        },
        {
          text: 'Archivada',
          value: 'activo',
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
        } ],"items":_vm.tareas},scopedSlots:_vm._u([{key:"item.fecha_inicio",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format_date(item.fecha_inicio)))]}},{key:"item.fecha_fin",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format_date(item.fecha_fin)))]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('Xbtn',{attrs:{"icon":"","color":"primary","permiso":"update_tareas"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("edit")])],1),_c('Xbtn',{attrs:{"icon":"","color":"red","permiso":"delete_tareas"},on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-icon',[_vm._v("delete")])],1)]}},{key:"item.activo",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"outlined":"","color":!item.activo ? 'green' : 'red',"dark":""}},[_vm._v(_vm._s(!item.activo ? "Sí" : "No"))])]}}],null,false,3295635855)}),(_vm.show_dialog_tarea)?_c('v-dialog',{on:{"click:outside":function($event){return _vm.close_dialog()}},model:{value:(_vm.show_dialog_tarea),callback:function ($$v) {_vm.show_dialog_tarea=$$v},expression:"show_dialog_tarea"}},[_c('TareaFormulario',{attrs:{"id_tarea":_vm.id_tarea},on:{"close":function($event){return _vm.close_dialog()}}})],1):_vm._e()]:[_c('TareasListaMovil',{attrs:{"id_inventario":_vm.$route.params.id}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }